<template>
  <!-- 充值流水 -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="充电站">
          <SelectStation width="250px" v-model="searchData.station_id"></SelectStation>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="searchData.pay_status" placeholder="请选择" size="small">
            <el-option v-for="item in dict_order_appoint_status" :key="item.key" :label="item.name" :value="item.key"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="预约时间">
          <el-date-picker
            v-model="searchData.start_time_range"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="small"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <tp-table
      :isNeedSerialNumber="false"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
  </div>
</template>

<script>
import { getListAPI } from './api'
import { dict_order_appoint_status } from '@/enum/dict.js'
import SelectStation from '@/views/components/select-station.vue'
import { authBtnMixin } from '@/mixins/authBtnMixin'
const columns = [
  {
    label: '用户ID',
    prop: 'uid',
    minWidth: '90',
    customRender(h, row) {
      return <div>{row['uid']}</div>
    }
  },
  {
    label: '用户信息',
    prop: 'user',
    minWidth: '170',
    customRender(h, row) {
      return (
        <div>
          <p class="table-item">昵称：{row['user_name']}</p>
          <p class="table-item">手机号：{row['mobile']}</p>
        </div>
      )
    }
  },

  {
    label: '车牌号',
    prop: 'car_no',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['car_no']}</p>
    }
  },
  {
    label: '充电站',
    prop: 'station_name',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['station_name']}</p>
    }
  },
  {
    label: '停车位编号',
    prop: 'space_number',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['space_number']}</p>
    }
  },
  {
    label: '地锁编号',
    prop: 'equipment_id',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['equipment_id']}</p>
    }
  },
  {
    label: '充电枪编号',
    prop: 'gun_number',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['gun_number']}</p>
    }
  },
  {
    label: '订单编号',
    prop: 'order_no',
    minWidth: '150',
    customRender(h, row) {
      return <div>{row['order_no']}</div>
    }
  },

  {
    label: '状态',
    prop: 'order_status',
    minWidth: '110',
    customRender(h, row) {
      return (
        <div>
          <el-tag size="small" type={this.filterStatusType(row['order_status'])}>
            {this.filterStatusName(row['order_status'])}
          </el-tag>
        </div>
      )
    }
  },
  {
    label: '时间',
    prop: '',
    minWidth: '250',
    customRender(h, row) {
      return (
        <div>
          <div>开始时间：{row['start_time'] || '-'}</div>
          <div>结束时间：{row['end_time'] || '-'}</div>
          <div>取消时间：{row['cancel_time'] || '-'}</div>
        </div>
      )
    }
  },

  {
    label: '操作',
    minWidth: '120',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link 
           disabled={!this.isShowBtn(this.AUTH_BTN.order_appoint_detail)}
          underline={false} type="primary" onClick={() => this.$router.push({ name: 'OrderAppointDetail', params: { id: row['id'] } })}>
            详情
          </el-link>
        </div>
      )
    }
  }
]
export default {
  mixins:[authBtnMixin],
  name: 'List',
  components: { SelectStation },
  data() {
    return {
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        station_id: '',
        order_status: '',
        start_time_range: []
      },
      dict_order_appoint_status
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    this.getList()
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  methods: {
    async getList() {
      const { station_id, order_status, start_time_range } = this.searchData
      let params = {
        page: this.currentPage,
        page_size: this.pageSize,
        station_id,
        order_status
      }
      if (start_time_range && start_time_range.length > 0) {
        params.start_time_range = `${start_time_range[0]},${start_time_range[1]}`
      }
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },

    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    filterStatusName(status) {
      return this.dict_order_appoint_status.find(item => item.key == status).name
    },
    filterStatusType(status) {
      let type = 'primary'
      switch (status) {
        case 0:
          type = 'primary'
          break
        case 1:
          type = 'warning'
          break
        case 2:
          type = 'danger'
          break
        case 3:
          type = 'success'
          break

        default:
          break
      }
      return type
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .header-search {
    .el-form {
      .el-form-item {
        margin-right: 35px;
        .el-input {
          width: 250px;
        }
        // .el-date-editor {
        //   .el-range-separator {
        //     padding: 0;
        //   }
        //   .el-range-input {
        //     width: 35%;
        //   }
        // }
      }
      // .el-select .el-input {
      //   width: 100px;
      // }
      // .input-with-select {
      //   vertical-align: middle;
      // }
      // .input-with-select .el-input-group__prepend {
      //   background-color: #fff;
      // }
    }
  }
  .el-divider--horizontal {
    margin: 0 0 10px;
  }

  .tp-table {
    .el-table__fixed-right {
      height: 100% !important;
    }
    // .table-item {
    //   width: 100px;
    //   text-align: right;
    // }
  }
  .el-dialog__body {
    padding-top: 0;
  }
}
</style>
